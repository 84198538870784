import { InputHTMLAttributes } from "react";
import './input.css';

interface Props extends InputHTMLAttributes<HTMLInputElement>{
    placeholderPosition?:'inside' | 'outside';
    placeholder:string;
}

export function Input({placeholderPosition='outside',placeholder,...props}:Props){

    if(placeholderPosition==='inside'){
        return(
            <input {...props} placeholder={placeholder}/>
        );
    };
    if(placeholderPosition==='outside'){
        return(
            <div className="input__container">
                <label htmlFor="input" className="input--label">{placeholder}:</label>
                <input id="input" {...props}/>
            </div>
        );
    };
    return null;

};