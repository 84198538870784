import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as fs from 'fs';

export function ScrollToTop(){
    const location = useLocation();

    useEffect(()=>{
        window.scrollTo(0,0);
    },[location]); 

    return null;
}

export function scrollTo(id: string,offset?:number) {
    const element = document.getElementById(id);
    
    if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: elementPosition -( offset?? 0) ,
            behavior: 'smooth'
        });
    }
}

export function addAnimation() {
    const banner = document.getElementById('hero-bottom');
    const btn = banner?.getElementsByTagName('button')[0];
    btn?.classList.add('animated');

    setTimeout(
      () => btn?.classList.remove('animated')
    ,1000);
};
