import { NewBodyBlock } from "../bodyBlock/bodyBlock";
import './process.css';
import { scrollTo } from "../../functions/functions";
import { TwoColsCustom } from "../../components/smallBlocks/smallBlocks";
import { List,ListItemProps } from "../../components/list/list";

const listItems:Omit<ListItemProps, 'number'>[]= [
    {
        title:'Analýza úlohy',
        children:<p>Každý projekt začíná tím, že si zanalyzuji, co a proč vlastně budu dělat.</p>,
        id:'analyza',
    },
    {
        title:'Návrh řešení',
        children:<p>Teď přichází ta fáze, kdy sáhnu po tužce a papíru, protože vždy se musí někde začít. Namaluji pár čar, schémat a..., struktura projektu je na světě! Možná trochu křivá, ale to se vždy vyladí.</p>,
        id:'navrh'
    },
    {
        title:'Frontend',
        children:
                <>
                    <p>Frontend je to, co uživatele zaujme jako první. Dnes se čím dál častěji využívají mobilní telefony, proto je responzivita důležitým faktorem. Nikdo přeci nechce web, co vypadá jako puzzle s chybějícím dílkem.</p>
                    <p>Frontend i backend vyvíjím souběžně.</p>
                </>,
        id:'frontend'
    },
    {
        title:'Backend',
        children:<>
                    <p>Vyžaduje-li projekt i funční část, vytvořím i backend.</p>
                    <p>Zatímco frontend je fasádou, backend je srdce a mozek celého projektu. Tady se dělají všechny ty tajné věci.</p>
                </>,
        id:'backend'
    },
    {
        title:'Doladění',
        children:<p>Na závěr přichází ta nejlepší část – hraní si s drobnostmi! Testuji, ladím a odstraňuji všechny ty drobné chyby, které by mohly celou práci znehodnotit.</p>,
        id:'ladeni'
    },
];  


export function NewProcessBlock(){

    return(
        <NewBodyBlock title="proces">
            <TwoColsCustom
                leftSize={1}
                rightSize={3}
                left={
                    <div className="process__nav">
                        <h3 className="process--small--head">Postup práce</h3>
                        <span className="process--separator"></span>
                        {listItems.map((item,index)=>(
                            <div className="process__nav--item" onClick={()=>item.id && scrollTo(item.id,92)} key={index}>
                                <span className="process--number">{'0'+(index+1)}</span>
                                <p>{item.title}</p>
                            </div>
                        ))}
                    </div>
                }
                right = {
                    <List
                        items = {listItems}
                    />
                }

            />
        </NewBodyBlock>
    );

};

