import React, { useEffect, useRef, useState } from "react";

interface ImageScrollerProps {
  src: string;
  alt: string;
  scrollSpeed?: number;
}

export function ImageScroller({
  src,
  alt,
  scrollSpeed = 0.03,
}: ImageScrollerProps) {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [imageTopPosition, setImageTopPosition] = useState(33.33);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top < windowHeight && rect.bottom > 0) {
        const distanceFromTop = windowHeight - rect.top;
        const newTop = 33.33 - (distanceFromTop * scrollSpeed);

        const constrainedTop = Math.min(Math.max(newTop, 0), 33.33);
        setImageTopPosition(constrainedTop);
      }
    }
  }, [scrollPosition, scrollSpeed]);

  return (
    <img
      ref={imageRef}
      src={src}
      alt={alt}
      className="scrollable--img"
      style={{
        top: `${imageTopPosition}%`,
      }}
    />
  );
}
