import { forwardRef } from 'react';
import './smallBlocks.css';

type TwoProps ={
    left:React.ReactNode,
    right:React.ReactNode,
    hasMargin?:boolean, 
};

export function TwoCols({left,right,hasMargin}:TwoProps){

    return(
        <div className={`two__container ${hasMargin ? 'margined':''}`}>
            <div className="two__left">
                {left}
            </div>
            <div className="two__right">
                {right}
            </div>
        </div>
    )

};

interface TwoCustomProps extends TwoProps {
    leftSize:number,
    rightSize:number, 
}

export const TwoColsCustom = forwardRef<HTMLDivElement, TwoCustomProps>(function TwoColsCustom({left,right,leftSize,rightSize,hasMargin=false}:TwoCustomProps,ref){

    return(
        <div className={`two__container__custom ${hasMargin ? 'margined':''}`} style={{gridTemplateColumns:`${leftSize}fr ${rightSize}fr`}} ref={ref}>
            <div className="two__left">
                {left}
            </div>
            <div className="two__right">
                {right}
            </div>
        </div>
    )

});

type ThreeProps ={
    left:React.ReactNode,
    center:React.ReactNode,
    right:React.ReactNode,
    hasMargin?:boolean, 
};

export function ThreeCols({left,center,right,hasMargin}:ThreeProps){

    return(
        <div className={`three__container ${hasMargin ? 'margined':''}`}>
            <div className="three__left">
                {left}
            </div>
            <div className="three__center">
                {center}
            </div>
            <div className="three__right">
                {right}
            </div>
        </div>
    )

};