import './tag.css';

type Props = {
    children:React.ReactNode,
};

export function Tag({children}:Props){

    return(
        <div className="tag tx-xs">{children}</div>
    );
}

export function SmallHeader({children}:Props){

    return(
        <h3 className="small__header tx-sm">
            {children}
        </h3>
    );
};