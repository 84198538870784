import { CvItem } from "../../components/cvItem/cvItem";
import { IoSchoolOutline, IoHammerOutline , IoArrowForwardOutline} from "react-icons/io5";
import { LuBaby } from "react-icons/lu";
import { Button } from "../../components/button/button";
import './aboutBlock.css';
import { useNavigate } from "react-router-dom";
import { SmallHeader } from "../../components/tag/tag";

const born = new Date('2000-03-01').getFullYear();
const age = Math.abs((new Date).getFullYear() - born);

export function AboutBlock(){

     const navigate = useNavigate();

     const handleDownloadAndOpenPdf = async () => {
          const pdfUrl = '/documents/resume.pdf';
      
          try {
            const response = await fetch(pdfUrl);
      
            if (!response.ok) {
              throw new Error("Chyba při stahování PDF souboru");
            }
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, "_blank");
          } catch (error) {
            console.error("Nastala chyba:", error);
          }
        };

    return(
        <div className="about__block">
            <div className="about__left">
                <SmallHeader>Životopis</SmallHeader>
                <h2 className="h-lg">Cesta životem</h2>
                <p className="tx-md">„Největší chyba, kterou v životě můžete udělat, je mít pořád strach, že nějakou uděláte.“</p>
                <h3 className="tx-sm" style={{textAlign:'right'}}><a href="https://en.wikipedia.org/wiki/Elbert_Hubbard" target="_blank">- Elbert Hubbard</a></h3>
                <div className="cv--nav">
                    <Button variant="primary" onClick={handleDownloadAndOpenPdf} style={{wordSpacing:'0.5rem'}}>Chci CV</Button>
                    <Button variant="ghost" style={{fontSize:'1rem'}} onClick={()=>navigate('/kontakt')}>KONTAKT <IoArrowForwardOutline/></Button>
                </div>
            </div>
            <div className="about__right">
                <CvItem interval="1/2000" title="Tady to všechno začalo" marker={<LuBaby/>}></CvItem>
                <CvItem interval="9/2004 - 6/2006" title="Mateřská škola" marker={<IoSchoolOutline/>}>MŠ Vsetín Trávníky 1218, příspěvková organizace</CvItem>
                <CvItem interval="9/2006 - 6/2015" title="Základní škola" marker={<IoSchoolOutline/>}>
                     Základní škola Vsetín, Trávníky 1217, příspěvková organizace
                </CvItem>
                <CvItem interval="9/2015 - 6/2019" title="Střední škola" marker={<IoSchoolOutline/>}>
                    Masarykovo gymnázium, Střední zdravotnická škola a Vyšší odborná škola zdravotnická Vsetín Tyršova 1069 755 01 Vsetín
                </CvItem>
                <CvItem interval="9/2019 - 6/2024" title="Vysoká škola" marker={<IoSchoolOutline/>}>
                     Vysoké učení technické v Brně, Fakulta strojního inženýrství, Ústav automatizace a informatiky
                </CvItem>
                <CvItem interval="7/2020 - 8/2020, 7/2021 - 8/2021" title="Brigáda" marker={<IoHammerOutline/>}>
                    M&V, divize STIMZET, Vsetín
                </CvItem>
                <CvItem interval="10/2022 - 8/2024" title="Internship" marker={<IoHammerOutline/>} tags={['T-SQL','Power BI','Visual Basic']}>
                     Thermo Fisher Scientific Brno s.r.o.
                </CvItem>
                <CvItem interval="7/2024..." title="Full-stack developer" marker={<IoHammerOutline/>} tags={['HTML','TypeScript','React','Redux','Tailwind CSS','MySQL','Python']}>
                    Vysoké učení technické v Brně, Fakulta strojního inženýrství, Ústav procesního inženýrství
                </CvItem>
            </div>
        </div>
    )
};