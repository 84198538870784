import './firework.css';

export function Firework(){

    return(
        <div className="firework__container">
            <div className="firework"></div>
            <div className="firework"></div>
            <div className="firework"></div>
        </div>
    );
};