import { IoCheckmarkOutline, IoLogoGithub, IoLogoLinkedin, IoSchool,IoCodeSlashOutline,IoPersonOutline } from 'react-icons/io5';
import { TbAdjustmentsCode } from "react-icons/tb";
import './profile.css';
import { useState } from 'react';
import { ProgressPoints } from '../../components/progressPoints/progressPoints';
import { Slider } from '../../components/slider/slider';

export function ProfileBlock(){
    const [activeIndex,setActiveIndex] = useState(0);

    return(
        <div className="profile__block">
            <div className="profile__left">
                <img src="/kolaz.webp" alt='profile-image'/>
                <div className="profile__left--shadow"></div>
                <div className="name__container">
                    <h1 className='profile--title'>Matyáš Zbranek</h1>
                    <p className='profile--job'>Web developer</p>
                    <div className="profile--socials">
                        <a target="_blank" href="https://www.linkedin.com/in/matyaszbranek/"><IoLogoGithub/></a>
                        <a href="https://github.com/ZbrMa" target="_blank"><IoLogoLinkedin/></a>
                        
                    </div>
                </div>
            </div>
            <div className="profile__right">
                <h1 className='h-xl'>Něco o mně</h1>
                <div className="profile__basic">
                    <span>24 let</span>
                    <span><a href='https://www.google.com/maps?q=Janová' target='_blank'>Janová</a></span>
                </div>
                <div className="profile--text tx-sm">
                Jmenuji se Matyáš, je mi 24 let a pocházím z Valašska, což znamená, že kopce a slivovici mám v krvi. 
                Ve volném čase si brnkám na kytaru (a tajně si představuji, že jsem další člen Oasis nebo Beatles), čtu si časopisy o historii nebo články o moderních technologiích.
                Miluju přírodu a rád poznávám nová místa. Jsem cílevědomý introvert, mám smysl pro detail a nemám rád zeleninu.
                </div>
                <div className="profile__bottom">
                    <div className="profile__nav">
                        <div className={`profile__nav--item tx-sm ${activeIndex === 0 ? 'active':''}`} onClick={()=>setActiveIndex(0)}>
                            <IoSchool/>
                            Zájmy
                        </div>
                        <div className={`profile__nav--item tx-sm ${activeIndex === 1 ? 'active':''}`} onClick={()=>setActiveIndex(1)}>
                            <IoCodeSlashOutline/>
                            Frontend
                        </div>
                        <div className={`profile__nav--item tx-sm ${activeIndex === 2 ? 'active':''}`} onClick={()=>setActiveIndex(2)}>
                            <TbAdjustmentsCode/>
                            Backend
                        </div>
                        <div className={`profile__nav--item tx-sm ${activeIndex === 3 ? 'active':''}`} onClick={()=>setActiveIndex(3)}>
                            <IoPersonOutline/>
                            Soft skills
                        </div>
                    </div>
                     
                     <Slider activeIndex={activeIndex}>
                        <div className="interests__container">
                            <div className="interest--item tx-sm">
                                <IoCheckmarkOutline/>
                                <span>Kytara</span>
                            </div>
                            <div className="interest--item tx-sm">
                                <IoCheckmarkOutline/>
                                <span>Historie</span>
                            </div>
                            <div className="interest--item tx-sm">
                                <IoCheckmarkOutline/>
                                <span>Turistika</span>
                            </div>
                            <div className="interest--item tx-sm">
                                <IoCheckmarkOutline/>
                                <span>Moderní technologie</span>
                            </div>
                        </div>
                        <div className="skills__container">
                            <div className="skill--item">
                                <h3 className='tx-xs'>HTML</h3>
                                <ProgressPoints progress={7}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>CSS</h3>
                                <ProgressPoints progress={7}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>TypeScript</h3>
                                <ProgressPoints progress={5}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>React</h3>
                                <ProgressPoints progress={5}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>Redux</h3>
                                <ProgressPoints progress={3}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>Tailwind CSS</h3>
                                <ProgressPoints progress={7}/>
                            </div>
                        </div>
                        <div className="skills__container">
                            <div className="skill--item">
                                <h3 className='tx-xs'>Node.js</h3>
                                <ProgressPoints progress={2}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>MySQL</h3>
                                <ProgressPoints progress={6}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>Python</h3>
                                <ProgressPoints progress={5}/>
                            </div>
                        </div>
                        <div className="skills__container">
                            <div className="skill--item">
                                <h3 className='tx-xs'>Spolupráce</h3>
                                <ProgressPoints progress={7}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>Komunikace</h3>
                                <ProgressPoints progress={6}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>Kreativita</h3>
                                <ProgressPoints progress={7}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>Motivace</h3>
                                <ProgressPoints progress={8}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>Řešení problémů</h3>
                                <ProgressPoints progress={8}/>
                            </div>
                            <div className="skill--item">
                                <h3 className='tx-xs'>Zodpovědnost</h3>
                                <ProgressPoints progress={7}/>
                            </div>
                        </div>
                     </Slider>
                </div>
            </div>
        </div>
    );
}