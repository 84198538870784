import { Page } from "./src/Page";
import './src/todoIndex.css';
import './src/App.css';
import { Button } from "../components/button/button";
import { IoArrowBackOutline } from "react-icons/io5";
import {useNavigate} from 'react-router-dom';

export function ToDoApp(){

    const navigate = useNavigate();
    return(
        <div className="todo-app body-shadow">
            <Page/>
            <Button onClick={()=>navigate('/prace')} style={{display:'flex', gap:'8px', alignItems:'center', position:'absolute',left:'16px',top:'16px',color:'white'}}>
                <IoArrowBackOutline></IoArrowBackOutline> Zpět k projektům
            </Button>
        </div>
        
    );
};