import './progressPoints.css';

type Props = {
    progress:1 | 2 | 3 | 4 |5|6|7|8|9|10,
};

export function ProgressPoints({progress}:Props){

    return(
            <div className="progress__points">
                {Array.from(Array(10), (e, i) => {
                    return <span className={`progress--point ${i<progress ? 'highlight':''}`} key={i}></span>
                })}
            </div>
    );
};   