import { useState,useEffect,useRef, CSSProperties,memo, act } from "react";
import React from "react";
import './slider.css';

type AutoProps = {
    children:React.ReactNode,
    styles?:CSSProperties,
    interval:number,
    returnIndex?:(index:number)=>void,
};

function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
};

export const InfiniteSlider = memo(({children,styles,interval,returnIndex}:AutoProps) => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const [position,setPosition] = useState(0);
    const [childWidth, setChildWidth] = useState(0);
    const [index,setIndex] = useState(0);

    let childrenArray = React.Children.toArray(children);    
    
    const updateSizes = () =>{
        if (carouselRef.current != null && childrenArray.length>0){
            setChildWidth(carouselRef.current.offsetWidth / (childrenArray.length + 1));
            carouselRef.current.style.transform = `translateX(0px)`;
        };
    };

    useEffect(() => {
        updateSizes();
        window.addEventListener('resize', updateSizes);

        return () => {
            window.removeEventListener('resize', updateSizes);
        };
    }, [children,carouselRef]);

    const moveRight = async () => {
        if (carouselRef.current !=null && childrenArray.length>0) {
            const newPosition = position - carouselRef.current.offsetWidth / (childrenArray.length + 1);
            carouselRef.current.style.transform = `translateX(${newPosition}px)`;
            await timeout(1000);
            setIndex(prev=>(prev+1 < childrenArray.length ? prev + 1 : 0));
            if (carouselRef.current !=null && carouselRef.current.firstChild) {
                carouselRef.current.style.transition = 'none';
                const clonedNode = carouselRef.current.firstChild.cloneNode(true);
                carouselRef.current.appendChild(clonedNode);
                carouselRef.current.firstChild && carouselRef.current.removeChild(carouselRef.current.firstChild);  
                carouselRef.current.style.transform = `translateX(0px)`;
                await timeout(1000);
                if (carouselRef.current !=null) carouselRef.current.style.transition = 'transform 1s ease-in-out';
            };               
        };
    };

    useEffect(() => {
        const moveInterval = setInterval(() => {
            moveRight();
        }, interval*1000);
    
        return () => clearInterval(moveInterval);
    }, [childWidth,carouselRef]);

    useEffect(()=>{
        if (returnIndex)
        returnIndex(index);
    },[index]);

    return(
        <div className="auto-slider" style={styles}>
            <div className="outer-carousel">
                <div className="slider-moving" ref={carouselRef} id="carousel" style={{width:`${'calc( ' + (childrenArray.length + 1) + ' * 100% )'}`, gridTemplateColumns:`repeat(${childrenArray.length + 1},1fr)`}}>
                    {childrenArray}
                </div>
            </div>
        </div>
    );
});


type IndexProps = {
    children:React.ReactNode,
    styles?:CSSProperties,
    activeIndex:number,
};


export const Slider = memo(({children,styles,activeIndex}:IndexProps) => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const [childWidth, setChildWidth] = useState(0);

    let childrenArray = React.Children.toArray(children);    
    
    const updateSizes = () =>{
        if (carouselRef.current != null){
            setChildWidth(carouselRef.current.offsetWidth / (childrenArray.length));
            carouselRef.current.style.transform = `translateX(0px)`;
        };
    };

    useEffect(() => {
        updateSizes();
        window.addEventListener('resize', updateSizes);

        return () => {
            window.removeEventListener('resize', updateSizes);
        };
    }, [children,carouselRef]);

    const move = async (activeItem:number) => {
        if (carouselRef.current !=null && childrenArray) {
            const newPosition = -activeItem * childWidth;
            carouselRef.current.style.transform = `translateX(${newPosition}px)`;
        };
    };

    useEffect(()=>{
        move(activeIndex);
    },[activeIndex]);

    return(
        <div className="auto-slider" style={styles}>
            <div className="outer-carousel">
                <div className="slider-moving" ref={carouselRef} id="carousel" style={{width:`${'calc( ' + (childrenArray.length) + ' * 100% )'}`, gridTemplateColumns:`repeat(${childrenArray.length},1fr)`}}>
                    {childrenArray}
                </div>
            </div>
        </div>
    );
});