import { useNavigate } from "react-router-dom";
import { Button } from "../components/button/button";

export function NotFound(){

    const navigate = useNavigate();

    return(
            <div className="not__found">
                <div className="not__found__body">
                    <h2>404</h2>
                    <p>Něco se pokazilo</p>
                    <Button onClick={()=>navigate('/')}>Domů</Button>
                </div>
                <img src="/robot.png"/>
            </div>        
    )
};