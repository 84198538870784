import './App.css';
import './global.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Domu  from './pages/Domu';
import Kontakt from './pages/Kontakt';
import { Projekty } from './pages/Projekty';
import { ToDoApp } from './todo-app/ToDoApp';
import PocasiApp from './weather-app/src/WeatherApp';
import { About } from './pages/About';
import { ScrollToTop } from './functions/functions';
import { NotFound } from './pages/notFound';

function App() {

  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Domu/>}/>
        <Route path='/prace' element={<Projekty/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/kontakt' element={<Kontakt/>}/>
        <Route path='/projekt/todo-app' element={<ToDoApp/>}/>
        <Route path='/projekt/pocasi-app/*' element={<PocasiApp/>}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
