import './banner.css';
import { Button } from '../button/button';
import { IoArrowDown } from "react-icons/io5";
import { scrollTo, addAnimation } from '../../functions/functions';
import { BsArrowDown } from "react-icons/bs";
import { useEffect } from 'react';
import { SmallHeader } from '../tag/tag';

export function Banner(){

    return(
        <section className="hero__banner">
            <div className="banner--right"></div>
            <div className="banner--left">
                <div className="banner--shape1"/>
                <div className="banner--shape2"/>
                
                <div className="banner__content">
                    <div className="banner__content--inner">
                        <h1 className="banner--title">Matyáš Zbranek</h1>
                        <p className="banner--text">Web developer pendlující mezi Vsetínem a Brnem</p>
                    </div>     
                </div>
            </div>
            <div className='banner__bottom' onClick={()=>scrollTo('work-cont')}>
                <Button style={{fontSize:'2rem',margin:'auto',color:'var(--orange)'}} variant='ghost'><IoArrowDown/></Button>
            </div>
       </section>
    );
};

export function BannerNew(){

    useEffect(()=>{
        const arrowInterval = setInterval(addAnimation,5000);

        return ()=>clearInterval(arrowInterval);
    },[]);

    return(
        <div className="hero">
            <div className="hero__inner">
                <div className="hero__left">
                    <div className="hero--title__container">
                        <h1 className='hero--title'>Matyáš</h1>
                        <h1 className='hero--title--sec'>Zbranek</h1>
                    </div>
                    <div className='hero__bottom' onClick={()=>scrollTo('work-cont')} id='hero-bottom'>
                        <Button style={{fontSize:'3rem',margin:'auto',height:'48px',width:'48px',color:'var(--orange)'}} variant='ghost'><BsArrowDown/></Button>
                    </div>
                </div>
                <div className="hero__right">
                    <SmallHeader>Web developer</SmallHeader>
                    <h2 className='h-lg'>Web developer pendlující mezi Vsetínem a Brnem</h2>
                    <p className='hero--text'>Jsem začínající web developer, který se rád učí novým věcem a rozhodně se nebojí výzev.</p>
                </div>
            </div>
        </div>
    )
};