import { NewBodyBlock } from "../bodyBlock/bodyBlock";
import { WorkCard } from "../../components/workCard/workCard";
import './work.css';
import { MdOutlineDisplaySettings } from "react-icons/md";
import { Button } from "../../components/button/button";
import { useNavigate } from "react-router-dom";
import { IoArrowForwardOutline } from "react-icons/io5";
import { FaGears } from "react-icons/fa6";
import { BsClipboard2Data } from "react-icons/bs";
import { TwoColsCustom } from "../../components/smallBlocks/smallBlocks";
import { List, ListItemProps } from "../../components/list/list";
import {ImageScroller} from "../../components/scrollableImg/scrollableImg";

const listItems:Omit<ListItemProps, 'number'>[]= [
    {
        title:'Web design',
        children:<p>Věnuji se web designu, kde kladu důraz na estetiku a skvělý uživatelský zážitek.</p>
    },
    {
        title:'Webové stránky',
        children:<p>Navrhuji a tvořím moderní prezentáční stránky, osobní weby, blogy a spoustu dalších.</p>
    },
    {
        title:'Webové aplikace',
        children:<p>Umím vytvářet i webové aplikace, které vyžadují práci s databázemi nebo službami třetích stran.</p>
    },
];   

export function Intro(){

    const navigate = useNavigate();

    return(
        <NewBodyBlock variant="dark" title="práce" other={{id:'work-cont'}}>
            <div className="work__container">
                <TwoColsCustom
                    leftSize={1}
                    rightSize={3}
                    left={
                        <h2 className="h-lg">Co umím?</h2>
                    }
                    right={
                        <>
                        <p>
                            Zaměřuji se na tvorbu moderních a responzivních webových aplikací. Mým cílem je přinášet <strong>uživatelsky přívětivé a výkonné</strong> řešení pro každý projekt.
                        </p>
                        <p>
                            Věnuji se <strong>frontendu</strong> i <strong>backendu</strong> (frontend mám rozhodně raději), což mi umožňuje navrhovat aplikace jak vizuálně atraktivní, tak i funkční.
                        </p>
                    </>
                    }
                />
                <TwoColsCustom
                    leftSize={1}
                    rightSize={1}
                    left={
                        <ImageScroller src="/wireframe.jpg" alt="wireframe"/>
                       
                    }
                    right={
                        <>
                        <List
                            items={listItems}
                        />
                        <Button onClick={()=>navigate('/prace')} variant='ghost' style={{marginTop:'64px'}}>Projekty <IoArrowForwardOutline/></Button>
                        </>
                    }
                />
            </div>
        </NewBodyBlock>
    );
};

export function KeysBlock(){

    return(
        <NewBodyBlock variant="light"> 
            <div className="work--keys--top"></div>
            <div className="work--keys--bottom"></div>    
                <div className="work__container--keys">
                    <WorkCard title="Moderně" image={<MdOutlineDisplaySettings/>}>Zaměřuji se na snadné ovládání, uživatelský zážitek a responzivitu. Řídím se heslem: "V jednoduchosti je síla."</WorkCard>
                    <WorkCard title="Efektivně" image={<BsClipboard2Data />}>Kladu důraz na rychlé načítání, bezpečnost a účelnost.</WorkCard>
                    <WorkCard title="Funkčně" image={<FaGears/>}>Mým cílem je funkčnost, stabilita a škálovatelnost pro případný rozvoj.</WorkCard>
                </div> 
        </NewBodyBlock>
    )
};