import './cvItem.css';
import { Tag } from '../tag/tag';

type Props = {
    children?:React.ReactNode,
    interval:string,
    title:string,
    marker:React.ReactNode,
    tags?:string[],
};

export function CvItem({children,interval,title,marker,tags}:Props){

    return(
        <div className="cv__container">
            <div className="cv__inner">
                <div className="cv--marker">{marker}</div>
                <div className="cv--content">
                    <h3 className='cv--time tx-sm'>{interval}</h3>
                    <h2 className='h-md'>{title}</h2>
                    <p className="cv--text tx-sm">
                        {children}
                    </p>
                    {tags &&
                        <div className="tags__container">
                            {tags.map((tag,index)=>(
                                <Tag key={index}>{tag}</Tag>
                            ))}
                        </div>
                    }
                    
                </div>
            </div>
        </div>
    );
};