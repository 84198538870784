import { ButtonHTMLAttributes } from "react";
import './button.css';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    children:React.ReactNode,
    variant?:'primary' | 'secondary' | 'ghost' | 'ghost-secondary',
}

export function Button({children,variant='primary',...props}:Props){
    return(
        <button className={`button ${variant}`} {...props}>{children}</button>
    );
};