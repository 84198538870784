import { MdOutlineEmail } from "react-icons/md";
import './contact.css';
import { Input } from "../../components/input/input";
import { Button } from "../../components/button/button";
import { FormEvent, useEffect, useState } from "react";
import { IContactForm } from "../../types/contact";
import { Spinner } from "../../components/spinner/spinner";
import { IoArrowForwardOutline } from "react-icons/io5";
import { IoLogoLinkedin } from "react-icons/io5";
import { IoLogoGithub } from "react-icons/io5";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlinePhone } from "react-icons/hi2";
import { Alert } from "../../components/alert/alert";
import { useSendEmail } from "../../api/connect";
import { TwoCols } from "../../components/smallBlocks/smallBlocks";

const initalFormValues:IContactForm = {
    name:'',
    surname:'',
    email:'',
    phone:'',
    message:''
};

export function Contact(){
    const [maxHeight,setMaxHeight] = useState<{upper:string,lower:string}>({upper:'50vh',lower:'400px'});
    const [form, setForm] = useState<IContactForm>(initalFormValues);
    const { data, loading, error, postData } = useSendEmail();

    const handleHeight = () => {
        const width = window.innerWidth;
        if(width < 768){
            setMaxHeight(
                {
                    upper:'fit-content',
                    lower:'fit-content'
                }
            );
        }
        else {
            setMaxHeight(
                {
                    upper:'50vh',
                    lower:'348px'
                }
            );
        };
    };

    useEffect(() => {
        const handleResize = () => handleHeight();
        window.addEventListener('resize', handleResize);
        handleHeight();
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    const handleFormSubmit = async (e:FormEvent) => {
        e.preventDefault();
        await postData(form);
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setForm(prev => ({
            ...prev,
            [name]: value,
        }));
    };    

    useEffect(() => {
        if (data) {
            setForm(initalFormValues);
        };
    }, [data]);

    return(
        <div className="contact__container">
            <TwoCols
                left={
                    <div className="contact__left">
                        <h2>Zůstaňme v kontaktu</h2>
                        <p className="contact--text">Jakýkoliv dotaz & diskuze</p>
                        <div className="contact__details">
                            <a className="contact--detail" href="mailto:zbranek.m@gmail.com" style={{color:('var(--orange)'), fontWeight:'500',textDecoration:'underline', textDecorationThickness:'2px'}}><MdOutlineEmail/> zbranek.m@gmail.com</a>
                            <a className="contact--detail" href="tel:+420731304640"><HiOutlinePhone/> +420 731 304 640</a>
                            <a className="contact--detail" href='https://www.google.com/maps?q=Janová' target='_blank'><IoLocationOutline/> Janová</a>
                            
                        </div>
                        <div className="contact__icons">
                            <a className="contact--icon" target="_blank" href="https://www.linkedin.com/in/matyaszbranek/"><IoLogoLinkedin/></a>
                            <a className="contact--icon" href="https://github.com/ZbrMa" target="_blank"><IoLogoGithub/></a>
                        </div>
                    </div>
                }
                right={
                    <>
                    <form className="contact__form" id="contact-form" onSubmit={handleFormSubmit}>
                        {loading &&(
                            <div className="form__loading"><Spinner/></div>
                        )}
                        <Input placeholder="Jméno" type="text" name="name" onChange={handleFormChange} value={form.name} required></Input>
                        <Input placeholder="Příjmení" type="text" name="surname" onChange={handleFormChange} value={form.surname} required></Input>
                        <Input placeholder="Email" type="email" name="email" onChange={handleFormChange} value={form.email} required></Input>
                        <Input placeholder="Telefon" type="phone" name="phone" onChange={handleFormChange} value={form.phone}></Input>
                        <textarea placeholder="Obsah zprávy..." name="message" onChange={handleFormChange} value={form.message}></textarea>
                    </form>
                    <Button type="submit" form="contact-form" style={{marginTop:'16px'}}>{loading? 'Odesílám...': 'Odeslat'} <IoArrowForwardOutline/></Button>
                    </>
                }
            />
            <Alert type="positive" trigger={() =>{return!!data && !error}}>{data}</Alert>
            <Alert type="negative" trigger={() =>{return!!data && error}}>{data}</Alert>
        </div>
    );
};