import { NewProcessBlock } from "../blocks/process/process";
import { NewTechStack } from "../blocks/techStack/techStack";
import { NewWhy } from "../blocks/why/why";
import { Intro, KeysBlock } from "../blocks/work/work";
import { BannerNew } from "../components/banner/banner";
import Layout from "./layout";


export default function Domu(){

    return(
        <Layout headerPos='sec'>
            <BannerNew/>
            <Intro/>
            <KeysBlock/>
            <NewProcessBlock/>
            <NewTechStack/>
            <NewWhy/>
        </Layout>
    );
}