import { NewBodyBlock } from "../blocks/bodyBlock/bodyBlock";
import { Contact } from "../blocks/contact/contact";
import Layout from "./layout";


export default function Kontakt(){

    return(
        <Layout>
            <NewBodyBlock innerStyle={{marginTop:'0'}} style={{paddingTop:'32px'}}>
                <Contact/>
            </NewBodyBlock>    
        </Layout>
    );
};