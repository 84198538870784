import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button/button";
import { NewBodyBlock } from "../bodyBlock/bodyBlock";
import './why.css';
import { IoArrowForwardOutline } from "react-icons/io5";
import { TwoColsCustom } from "../../components/smallBlocks/smallBlocks";
import { SmallHeader } from "../../components/tag/tag";
import { Wordle } from "../wordle/wordle";
import { List,ListItemProps } from "../../components/list/list";
import { ImageScroller } from "../../components/scrollableImg/scrollableImg";

const listItems:Omit<ListItemProps, 'number' | 'children'>[]= [
    {
        title:'Mladistvý pohled na věc',
    },
    {
        title:'Flexibilta',
    },
    {
        title:'Chuť rozvíjet se',
    },
    {
        title:'Spolehlivost',
    },
];  

export function NewWhy(){

    const navigate = useNavigate();
    return(
        <>
        <NewBodyBlock variant='light' title="nabízím">
        <TwoColsCustom
            leftSize={1}
            rightSize={1}
            
            left={          
                <ImageScroller src="/idea.jpg" alt="idea"/> 
            }
            right = {
                <div className="why__container">
                    <div className="why__header">
                            <h2 className="h-lg">Proč právě já?</h2>
                            <p className="why--text">Jsem začínající web developer s velkou chutí učit se a rozvíjet své dovednosti. I když nemám roky zkušeností, mohu přinést <strong>svěží pohled</strong> na vývoj moderních webů. Mým cílem je vytvářet funkční a estetické webové stránky. Každý nový projekt je pro mě <strong>příležitostí</strong> růst, učit se a <strong>posouvat</strong> své schopnosti.</p>
                        </div>
                    <List
                        items={listItems}
                        variant='small'
                    />
                    <Button variant='ghost' onClick={()=>navigate('/kontakt')}>Kontakt <IoArrowForwardOutline/></Button>
                </div>
            }
        />
      </NewBodyBlock>
      <NewBodyBlock>
        <TwoColsCustom
            leftSize={1}
            rightSize={3}
            left={
                <div className="wordle__header">
                    <SmallHeader>Hra</SmallHeader>
                    <h2 className="h-lg">Hra Wordle</h2>
                </div>
            }
            right = {
                <p className="wordle__container--text">Malá hra na závěr. Jedná se o moji verzi populární hry wordle, kdy hráč musí uhádnout tajemné slovo. Hráč má 6 pokusů. Pokud se písmeno vyskytuje ve slově, má oranžovou barvu. Pokud je písmeno na správném místě, má barvu zelenou. Hledané slovo je v českém jazyce, může obsahovat háčky i čárky.</p>
            }
        />
        <Wordle/>
      </NewBodyBlock>
      </>
    );
};