import { useEffect, useState, useRef } from "react";
import { useGetWord } from "../../api/connect";
import './wordle.css';
import { Button } from "../../components/button/button";
import { Firework } from "../../components/firework/firework";

const placeCaretAtEnd = (element: HTMLDivElement) => {
    if (!element) return;

    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(element);
    range.collapse(false);

    selection?.removeAllRanges();
    selection?.addRange(range);
};

export function Wordle() {
    const [end, setEnd] = useState(false);
    const [goal, setGoal] = useState<Array<string>>([]);
    const [letters, setLetters] = useState<Array<string>>(new Array(5).fill(""));
    const [currLetter, setCurrLetter] = useState(0);
    const [rows, setRows] = useState<string[][]>(Array(6).fill(new Array(5).fill("")));
    const [currentRow, setCurrentRow] = useState(0);
    const [finalText,setFinalText] = useState<string | null>(null);

    const letterRefs = useRef<(HTMLDivElement | null)[][]>(Array(6).fill([]).map(() => Array(5).fill(null)));

    const { data, getWord } = useGetWord();

    useEffect(() => {
        getWord();
    }, []);

    useEffect(() => {
        setGoal(data ? data.split("") : []);
    }, [data]);

    const handleTextChange = (e: React.FormEvent<HTMLDivElement>, rowIndex: number, letterIndex: number) => {
        let newText = "";
        if (e.currentTarget.textContent){
            newText = e.currentTarget.textContent[e.currentTarget.textContent.length-1];
        };
        
        e.currentTarget.textContent = newText;

        if (/^[a-zA-ZáčďéěíňóřšťúýžĚŠČŘŽÝÁÍÉ]$/.test(newText)) {
            const newLetters = [...letters];
            newLetters[letterIndex] = newText.toUpperCase();
            setLetters(newLetters);

            if (letterIndex < 4) {
                setCurrLetter(letterIndex + 1);
                const nextRef = letterRefs.current[rowIndex][letterIndex + 1];
                if (nextRef) {
                    nextRef.focus({ preventScroll: true });
                    placeCaretAtEnd(nextRef);
                }
            }
        }
    };

    useEffect(() => {
        if (currentRow >0 && currLetter  >=0){
            const currentRef = letterRefs.current[currentRow][currLetter];
            if (currentRef) {
                currentRef.focus({ preventScroll: true });
                placeCaretAtEnd(currentRef);
            }
        }
    }, [currLetter, currentRow]);


    const handleEnterDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && letters.every(letter => letter !== "")) {
            const newRows = [...rows];
            newRows[currentRow] = letters;
            setRows(newRows);
            setCurrLetter(0);

            setLetters(new Array(5).fill(""));

            if (letters.join("") === goal.join("")) {
                setFinalText('Výborně!');
                setEnd(true);
            }
            else if (currentRow < 5) setCurrentRow(prev => prev + 1);
            else{
                setFinalText(`Hledané slovo bylo : ${goal.join("")}. Zkus to znovu.`);
                setEnd(true);
            }
        };
    };

    const handleMouseClick = (letterIndex: number) =>{
        if(currLetter === 0 && currentRow === 0){
            const currentRef = letterRefs.current[currentRow][currLetter];
            if (currentRef) {
                currentRef.focus({ preventScroll: true });
                placeCaretAtEnd(currentRef);
            }
        } else {
            const currentRef = letterRefs.current[currentRow][letterIndex];
            if (currentRef) {
                currentRef.focus({ preventScroll: true });
                placeCaretAtEnd(currentRef);
            }
        }
        setCurrLetter(letterIndex);
    };

    const handleArrowDowm = (event: KeyboardEvent) => {
        if(event.key === 'ArrowRight'){
            if (currLetter < 4) {
                const nextLetterIndex = currLetter + 1;
                setCurrLetter(nextLetterIndex);
                const nextRef = letterRefs.current[currentRow][currLetter + 1];
                if (nextRef) {
                    nextRef.focus({ preventScroll: true });
                    placeCaretAtEnd(nextRef);
                }
            }
        }

        if(event.key === 'ArrowLeft'){
            if (currLetter >0 ) {
                const nextLetterIndex = currLetter - 1;
                setCurrLetter(nextLetterIndex);
                const nextRef = letterRefs.current[currentRow][currLetter - 1];
                if (nextRef) {
                    nextRef.focus({ preventScroll: true });
                    placeCaretAtEnd(nextRef);
                }
            }
        }
    };

    const handleDeleteDown = (e:KeyboardEvent)=>{
        if(e.key === 'Backspace'){
                const newLetters = [...letters];
                newLetters[currLetter] = "";
                setLetters(newLetters);
                const currRef = letterRefs.current[currentRow][currLetter];
                if(currRef) currRef.textContent = "";
                if(currLetter>0){
                    const nextRef = letterRefs.current[currentRow][currLetter - 1];
                        if (nextRef) {
                            nextRef.focus({ preventScroll: true });
                            placeCaretAtEnd(nextRef);
                        }
                    setCurrLetter(prev=> prev-1);
                    
                }
        };
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEnterDown);
        return () => {
            window.removeEventListener('keydown', handleEnterDown);
        };
    }, [letters, currentRow, goal]);

    useEffect(()=>{
        window.addEventListener('keydown', handleArrowDowm);
        window.addEventListener('keydown', handleDeleteDown);
        return () => {
            window.removeEventListener('keydown', handleArrowDowm);
            window.removeEventListener('keydown', handleDeleteDown);
        };
    },[currLetter]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
        if (e.key === 'ArrowRight') {
            e.preventDefault();
        }
        if (e.key === 'ArrowLeft') {
            e.preventDefault();
        }
    };

    const handleReset = () => {
        setLetters(new Array(5).fill(""));
        setRows(Array(6).fill(new Array(5).fill("")));
        setCurrentRow(0);
        setCurrLetter(0);
        getWord();
        setFinalText(null);
        setEnd(false);
    };

    return (
        <div className="wordle__container">
            {rows.map((_, rowIndex) => (
                <div className={`word__line ${currentRow === rowIndex ? 'active--row':''}`} key={rowIndex}>
                    {letters.map((_, letterIndex) => (
                        <div
                            className={`word--letter ${currentRow === rowIndex ? 'active--row':''} ${rows[rowIndex][letterIndex] === goal[letterIndex]?.toUpperCase() ? 'correct' : goal.findIndex(letter => letter.toUpperCase() === rows[rowIndex][letterIndex]) > -1 ? 'close' : ''}`}
                            key={letterIndex}
                            ref={el => letterRefs.current[rowIndex][letterIndex] = el}
                            contentEditable={!end && rowIndex === currentRow}
                            suppressContentEditableWarning
                            onInput={(e) => handleTextChange(e, rowIndex, letterIndex)}
                            onKeyDown={handleKeyDown}
                            onClick={()=>handleMouseClick(letterIndex)}
                        >
                            {rows[rowIndex][letterIndex]}
                        </div>
                    ))}
                </div>
            ))}
            {finalText && (
                <div className="wordle__endgame">
                    <div className="wordle__endgame__inner">
                        {finalText === "Výborně!" && <Firework/>}
                        <div className="wordle--text">{finalText}</div>
                        <Button onClick={handleReset} variant='primary'>Nová hra</Button>
                    </div>
                </div>
            )}
        </div>
    );
}
