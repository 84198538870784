import './workCard.css';

type Props = {
    title:string,
    children:React.ReactNode,
    image:React.ReactNode,
}

export function WorkCard({title,children,image}:Props){

    return(
        <div className={`work__card`}>
            <div className='work__card--img'>{image}</div>
            <div className="work__card--content">
                <h2 className='h-xl'>{title}</h2>
                <p className='work__card--text'>{children}</p>
            </div>
        </div>
    );
};