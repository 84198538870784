import { useEffect, useState} from "react";
import emailjs from 'emailjs-com';
import { IContactForm } from "../types/contact";
import axios from "axios";

export const useSendEmail = () => {
    const [data, setData] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchData = async (payload: IContactForm) => {
        
        const templateParams = {
            name: `${payload.name} ${payload.surname}`,
            email: payload.email,
            phone: payload.phone,
            message: payload.message
        };
        setLoading(true);
        try {
            const response = await emailjs.send('service_rugqpq5','template_qylxc9i',templateParams,'fwW18ecXhND0_dWwx');
            setData('Zpráva byla úspěnšně odeslána.');
            setError(false);
        } catch (err) {
            setError(true);
            setData('Zprávu se nepodařilo odeslat. Zkuste to později.');
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, postData: fetchData };
};

export const useGetWord = () =>{
    const [data, setData] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchWord = async() =>{
        setLoading(true);

        try {
            const fileContent = await axios.get('/documents/slova.txt',{responseType:'text'});
            const text:string = await fileContent.data;
            const words = text.split('\r\n');
            const word = words[Math.floor(Math.random() * words.length)].toUpperCase();
            setData(word);
            setError(false);
        } catch(err) {
            setError(true);
            setData(null);
        } finally {
            setLoading(false);
        }
    };

    return {data,loading,error,getWord:fetchWord};
};