import { useNavigate } from 'react-router-dom';
import './footer.css';
import { IoLogoLinkedin, IoLogoGithub } from 'react-icons/io5';

const thisYear = (new Date()).getFullYear();

export default function Footer(){

    const navigate = useNavigate();

    return(
        <footer>
            <div className='footer-inner'>
                <div className='footer-container left'>
                    <div className='footer-container-inner' style={{height: '100%'}}>
                        <div className="nav__logo" onClick={()=>navigate('/')}>
                            <img src="logo2.svg"/>
                        </div>
                    </div>
                </div>
                <div className='footer-container center'>
                    <div className='footer-container-inner'>
                        <h2 className='h-lg'>Kontakt</h2>
                        <a href="tel:+420731304640">+420 731 304 640</a>
                        <a href="mailto:zbranek.m@gmail.com">zbranek.m@gmail.com</a>
                        <ul className='soc-media'>
                            <a target="_blank" href="https://www.linkedin.com/in/matyaszbranek/"><IoLogoGithub/></a>
                            <a href="https://github.com/ZbrMa" target="_blank"><IoLogoLinkedin/></a>
                        </ul>
                    </div>
                </div>
                <div className='footer-container right'>
                    <div className='footer-container-inner'>
                        <h2 className='h-lg'>Navigace</h2>
                        <a className='footer-btn' onClick={()=>navigate('/')}>Domů</a>
                        <a className='footer-btn' onClick={()=>navigate('/prace')}>Práce</a>
                        <a className='footer-btn' onClick={()=>navigate('/about')}>O mě</a>
                        <a className='footer-btn' onClick={()=>navigate('/kontakt')}>Kontakt</a>
                    </div>
                </div>
                <div className='footer-container bottom'>
                    <div className='footer-container-inner'>
                        ©Copyright {thisYear} ...
                    </div>
                </div>
            </div>
        </footer>
    );
};