import { useLocation, useNavigate } from "react-router-dom";
import './styles/sideNav.css';
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { LiaMapMarkedAltSolid } from "react-icons/lia";
import { CiLocationArrow1 } from "react-icons/ci";
import { Button } from '../../../components/button/button';
import { IoArrowBackOutline } from "react-icons/io5";

export function SideNav(){

    const navigate = useNavigate();
    const location = useLocation();

    return(
        <div className="side__nav">
            <div className="side__nav--inner">
                <div className="title">
                    Počasí
                </div>
                <nav className="nav__items">
                    <li className={`nav--item  ${location.pathname === 'projekty/pocasi/' ? 'active' : ''}`}nav--item onClick={()=>navigate('/projekt/pocasi-app/')}><MdOutlineSpaceDashboard/> Přehled</li>
                    <li className={`nav--item  ${location.pathname === 'projekty/pocasi/mapa' ? 'active' : ''}`} onClick={()=>navigate('/projekt/pocasi-app/mapa')}><LiaMapMarkedAltSolid/> Mapa</li>
                    <li className={`nav--item  ${location.pathname === 'projekty/pocasi/mista' ? 'active' : ''}`} onClick={()=>navigate('/projekt/pocasi-app/mista')}><CiLocationArrow1/> Moje lokace</li>
                </nav>
                <button style={{position:'absolute',bottom:'16px',left:'16px', display:'flex',alignItems:'center'}} onClick={()=>navigate('/prace')}><IoArrowBackOutline/> Zpět k projektům</button>
                </div>
        </div>
    );
};