import { useEffect, useRef } from 'react';
import './list.css';

type ListProps = {
    items:Omit<ListItemProps,'number'>[],
    variant?:'def'|'small',
};

export function List({items,variant='def'}:ListProps) {

    return(
        <div className={`list__container ${variant}`}>
            {items.map((item,index)=>(
                <ListItem title={item.title} number={index+1} id={item.id} key={index}>{item.children}</ListItem>
            ))}
        </div>
    );
};

export type ListItemProps = {
    title:string,
    children?:React.ReactNode,
    number:number,
    id?:string,
}


export function ListItem({title,children,number,id}:ListItemProps){

    const itemRef = useRef(null);

    useEffect(()=>{
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add("animate");
              }
            });
          });

          const itemElem = itemRef.current;
          if (itemElem) {
            observer.observe(itemElem);
          };

          return () => {
            if (itemElem) {
              observer.unobserve(itemElem);
            }
          };
    });

    return(
        <div className="list__item" id={id} ref={itemRef}>
            <div className="list__item--number">
                {number < 10 ? '0' + number : number}
            </div>
            <div className="list__item__body">
                <h3 className="list__item--title">{title}</h3>
                {children &&(<div className="list__item--text">
                    {children}
                </div>)}
            </div>
        </div>
    );
};