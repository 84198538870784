import { Link, useNavigate } from "react-router-dom";
import './header.css';
import { CSSProperties, useEffect, useState } from "react";
import { IoCloseOutline, IoMenuOutline } from "react-icons/io5";

type Props = {
    style?:CSSProperties,
    size?:'default' | 'small',
}

export default function Header({style,size}:Props){

    const [isMobile,setIsMobile] = useState(false);
    const [menuOpen,setMenuOpen] = useState(window.innerWidth<=768);
    const [scrolled,setScrolled] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!isMobile) {
          setMenuOpen(false);
        }
      }, [isMobile]);

    const handleResize = () =>{
        const windowHeight = window.innerHeight;
        const scroll = window.scrollY;
        const header = document.getElementsByTagName('header');

        if(scroll > 64 && scroll < windowHeight && header && !header[0].classList.contains('small')){
            header[0].style.opacity = '0';
        }
        else {
            header[0].style.opacity = '1';
        }

        if(scroll >= windowHeight){
            setScrolled(true);
            header[0].style.opacity = '1';
        }
        else {
            setScrolled(false);
        }
    };

    useEffect(()=>{
        window.addEventListener('scroll',handleResize);
        return ()=>window.removeEventListener('scroll',handleResize);
    },[])

    return(
        <>
        <header style={style} className={`${size ? size : ''} ${scrolled ? 'scrolled':''}`}>
            <div className={`head__inner ${menuOpen ? 'active':''}`}>
                <div className="logo__container" onClick={()=>navigate('/')}>
                    <img src="/logoWhite.svg" alt="bílý čtverec symbolizující okno ze kterého vystupjí dvě písmena"/>
                </div>
                <nav className="menu__container">
                    <ul className="menu--list">
                        <li className="menu--item" onClick={()=>setMenuOpen(false)}><Link to='/'>Domů</Link></li>
                        <li className="menu--item" onClick={()=>setMenuOpen(false)}><Link to='/prace'>Práce</Link></li>
                        <li className="menu--item" onClick={()=>setMenuOpen(false)}><Link to='/about'>O mně</Link></li>
                        <li className="menu--item" onClick={()=>setMenuOpen(false)}><Link to='/kontakt'>Kontakt</Link></li>
                    </ul>
                </nav>
                {isMobile && !menuOpen &&(<IoMenuOutline onClick={()=>setMenuOpen(true)} className="hamm--icon"></IoMenuOutline>)}
                {isMobile && menuOpen &&(<IoCloseOutline onClick={()=>setMenuOpen(false)} className="close--icon"></IoCloseOutline>)}

            </div>
        </header>
        </>
    );
};


type NavProps = {
    variant?:'def' | 'sec',
}

export function Nav({variant='def'}:NavProps){
    const [active,setActive] = useState(window.location.pathname);

    const [isMobile,setIsMobile] = useState(false);
    const [menuOpen,setMenuOpen] = useState(false);
    const [scrolled,setScrolled] = useState(false);

    const navigate = useNavigate();

    const handleItemClick = (path:string) =>{
        setActive(path);
        navigate(path);
        setMenuOpen(false);
    };

    const handleResize = () =>{
        const scroll = window.scrollY;

        if(scroll > 0){
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    };

    useEffect(()=>{
        window.addEventListener('scroll',handleResize);
        return ()=>window.removeEventListener('scroll',handleResize);
    },[]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <header className={`nav ${variant} ${scrolled ? 'scrolled':''}`}>
            <div className={`nav__inner ${menuOpen ? 'active':''}`}>
                <div className="nav__logo" onClick={()=>navigate('/')}>
                    <img src="logo2.svg"/>
                </div>
                <nav className="nav__container">
                    <ul className="nav--list">
                        <li className={`nav--item ${active === '/' ? 'active' : ''}`} onClick={()=>handleItemClick('/')}><Link to='/'>Domů</Link></li>
                        <li className={`nav--item ${active === '/prace' ? 'active' : ''}`} onClick={()=>handleItemClick('/prace')}><Link to='/prace'>Práce</Link></li>
                        <li className={`nav--item ${active === '/about' ? 'active' : ''}`} onClick={()=>handleItemClick('/about')}><Link to='/about'>O mně</Link></li>
                        <li className={`nav--item ${active === '/kontakt' ? 'active' : ''}`} onClick={()=>handleItemClick('/kontakt')}><Link to='/kontakt'>Kontakt</Link></li>
                    </ul>
                </nav>
                {isMobile && !menuOpen &&(<IoMenuOutline onClick={()=>setMenuOpen(true)} className="hamm--icon"></IoMenuOutline>)}
                {isMobile && menuOpen &&(<IoCloseOutline onClick={()=>setMenuOpen(false)} className="close--icon"></IoCloseOutline>)}
            </div>
        </header>
    )
};