import { useState } from "react";
import { IoChevronBackOutline, IoChevronForwardOutline, IoCloseOutline } from "react-icons/io5";
import { Button } from "../button/button";
import './gallery.css';

type Props = {
    images:string[],
    close:()=>void,
}

export function Gallery({images,close}:Props) {
    const [active,setActive] = useState(0);

    return (
        <div className="gallery__container">
            <div className="gallery__nav">
                <IoCloseOutline onClick={close} className="close--svg"/>
            </div>
            
            <div className="gallery__carousel">
                <IoChevronBackOutline onClick={()=>active > 0 && setActive(prev=> prev-1)} className={`${ active === 0 ? '' : 'chevron--active'}`}/>
                <div className="gallery__carousel__moving">
                    {images.map((image,index)=>(
                        <img className={`gallery--img ${active === index ? 'active' : ''}`} src={image} key={image}/>
                    ))}
                </div>
                <IoChevronForwardOutline onClick={()=>active < images.length -1 && setActive(prev=>prev+1)} className={`${ active >= images.length - 1 ? '' : 'chevron--active'}`}/>
            </div>
            
        </div>
    );
};