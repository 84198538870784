import "./techStack.css";
import {NewBodyBlock } from "../bodyBlock/bodyBlock";
import { useEffect } from "react";
import { TwoColsCustom } from "../../components/smallBlocks/smallBlocks";

export function NewTechStack () {

  const imagesBlink = (images: HTMLCollection) => {
    let i = 0;
  
    const blinkImage = () => {
      if (i < images.length) {
        const choosenImg = images[i] as HTMLImageElement;
        choosenImg.classList.add('active');
  
        setTimeout(() => {
          i++;
          blinkImage();
        }, 500);
        setTimeout(() => {
          choosenImg.classList.remove('active');
        }, 2000);
      } else {
        i = 0;
      }
    };
  
    blinkImage();
  };
  
  useEffect(() => {
    const container = document.getElementById('newtech__container');
    if (container) {
      const images = container.getElementsByTagName('img');
      if (images.length > 0) {
        imagesBlink(images);
        const blinkInterval = setInterval(() => imagesBlink(images), images.length / 2 * 2000);
        return () => clearInterval(blinkInterval);
      }
    }
  }, []);

  return(
    <NewBodyBlock variant="dark">
      <div className="tech__container">
        <TwoColsCustom
          leftSize={1}
          rightSize={3}
          left={
            <div className="tech__header">
              <h3 className='tech--tag'>Tech-stack</h3>
              <h2 className="h-lg">S čím pracuji?</h2>
            </div>
            
          }
          right={
            <p>Neustále sleduji <strong>nejnovější technologie</strong> a trendy ve webovém vývoji, abych mohl přinášet <strong>moderní</strong> a <strong>efektivní</strong> řešení. Držet krok s rychle se měnícím světem technologií je pro mě klíčové, protože mi to umožňuje vytvářet <strong>inovativní</strong> a <strong>kvalitní</strong> projekty.</p>
          }
        />
        <div className="newtech__container" id="newtech__container">
          <img src="/html.png" alt="1" />
          <img src="/css.png" alt="2" />
          <img src="/typescript.png" alt="3" />
          <img src="/react.png" alt="4" />
          <img src="/redux.png" alt="5" />
          <img src="/tailwind.png" alt="6" />
          <img src="/node.png" alt="7" />
          <img src="/mysql.png" alt="8" />
          <img src="/python.png" alt="9" />
          <img src="/git.png" alt="10" />
        </div>
      </div>
    </NewBodyBlock>
  );
};
