import { NewBodyBlock } from "../bodyBlock/bodyBlock";
import './projects.css';
import { useState } from "react";
import { Gallery } from "../../components/gallery/gallery";
import { TwoColsCustom } from "../../components/smallBlocks/smallBlocks";
import { ProjectCard } from "../../components/projectCard/projectCard";
import { SmallHeader } from "../../components/tag/tag";

export function Projects(){
    const [galerryOpened,setGalleryOpened] = useState("");

    return(
        <>
        <NewBodyBlock variant='light' innerStyle={{marginTop:'0'}}>
            <TwoColsCustom
                leftSize={1}
                rightSize={2}
                left={
                    <div className="projects__left">
                        <SmallHeader>Projekty</SmallHeader>
                        <h2>Ukázka mé práce</h2>
                    </div>
                }
                right={
                    <div className="projects__right">
                        <p>Zde je ukázka mé práce, která zahrnuje projekty z oblasti webového vývoje. Na těchto projektech je vidět propojení moderní technologie s kreativním přístupem.</p>
                    </div>
                    
                }
            />
            <div className="projects__grid">
                <ProjectCard 
                    img="/projekty/janzbranek.png"
                    title="Osobní web"
                    tag="Webová stránka"
                    buttonExternalLink="http://janzbranek.cz/"
                    buttonChild="Zjistit více"
                />
                <ProjectCard 
                    title="Popelka"
                    tag="Webová aplikace"
                    tags={['HTML','Tailwind CSS','React','Redux','Next.js','Python','MySQL']}
                >
                    <p className="tx-sm">Webová aplikace pro správu infrasturktury odpoadového hospodářství.</p>
                    <p className="tx-sm">Vyvíjeno Ústavem procesního inženýrství VUT Brno.</p> 
                </ProjectCard>
                <ProjectCard 
                    img="/projekty/pocasi1.png"
                    title="Počasí"
                    tag="Projekt"
                    buttonClick={()=>setGalleryOpened('pocasi')}
                    imageClick={()=>setGalleryOpened('pocasi')}
                    buttonChild="Zjistit více"
                    tags={['HTML','CSS','React','Redux','Axios']}
                />
                <ProjectCard 
                    img="/projekty/todoapp.png"
                    title="To-do aplikace"
                    tag="Projekt"
                    buttonChild="Zjistit více"
                    buttonClick={()=>setGalleryOpened('todo')}
                    imageClick={()=>setGalleryOpened('todo')}
                    tags={['HTML','Tailwind CSS','React','Context API']}
                />
                <ProjectCard 
                    img="/projekty/dekorace1.png"
                    title="Malý účetní systém"
                    tag="Webová aplikace"
                    buttonChild="Zjistit více"
                    buttonClick={()=>setGalleryOpened('ucet')}
                    imageClick={()=>setGalleryOpened('ucet')}
                    tags={['HTML','CSS','React','Node.js','Axios','MySQL']}
                />
                <ProjectCard 
                    img="/projekty/pivo.png"
                    title="Návrh pivního slideru"
                    tag="Web design"
                    buttonChild="Zjistit více"
                    buttonClick={()=>setGalleryOpened('pivo')}
                    imageClick={()=>setGalleryOpened('pivo')}
                />
            </div>
        </NewBodyBlock>

        {galerryOpened === "ucet" && (
            <Gallery
                images={["/projekty/dekorace1.png","/projekty/dekorace2.png","/projekty/dekorace3.png"]}
                close={()=>setGalleryOpened("")}
            />
        )}
        {galerryOpened === "pivo" && (
            <Gallery
                images={["/projekty/pivo.png"]}
                close={()=>setGalleryOpened("")}
            />
        )}
        {galerryOpened === "pocasi" && (
            <Gallery
                images={["/projekty/pocasi1.png","/projekty/pocasi2.png","/projekty/pocasi3.png","/projekty/pocasi4.png"]}
                close={()=>setGalleryOpened("")}
            />
        )}
        {galerryOpened === "todo" && (
            <Gallery
                images={["/projekty/todoapp.png"]}
                close={()=>setGalleryOpened("")}
            />
        )}
        </>
    );
};