import { Main } from './pages/Main';
import './WeatherApp.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Locations } from './pages/Locations';
import { Map } from './pages/Map';

function PocasiApp() {
  return (
    <div className="pocasi-app">
          <Routes>
            <Route path='' element={<Main/>}></Route>
            <Route path='mapa' element={<Map/>}></Route>
            <Route path='mista' element={<Locations/>}></Route>
          </Routes>
    </div>
  );
}

export default PocasiApp;
