import { Button } from "../button/button";
import './projectCard.css';
import { useNavigate } from "react-router-dom";
import { useState,useEffect,useRef } from "react";
import { Spinner } from "../spinner/spinner";
import { Tag } from "../tag/tag";

type ProjectProps = {
    children?:React.ReactNode,
    title:string,
    tag:string,
    img?:string,
    buttonLink?:string,
    buttonExternalLink?:string,
    buttonChild?:string,
    buttonClick?:()=>void,
    tags?:string[],
    imageClick?:()=>void,
};


export function ProjectCard({children,title,tag,img,buttonLink,buttonChild='Zjistit více',buttonExternalLink,buttonClick,tags,imageClick}:ProjectProps){

    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const imgRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 }
        );

        if (imgRef.current) {
        observer.observe(imgRef.current);
        }

        return () => {
        if (imgRef.current) observer.unobserve(imgRef.current);
        };
    }, []);

    const handleImageLoad = () => {
        setLoading(false);
    };

    const navigate = useNavigate();

    return(
        <div className="project__card">
            <div className="project__body">
                <div className="project__info">
                    <p className="project--tag">{tag}</p>
                    <h3 className="h-md">{title}</h3>
                </div>
                {/*<div className="project--text">
                    {children}
                </div>*/}
                {buttonLink && <Button onClick={()=>navigate(buttonLink)} variant='ghost'>{buttonChild}</Button>}
                {buttonClick && <Button onClick={buttonClick} variant='ghost'>{buttonChild}</Button>}
                {buttonExternalLink && <Button variant='ghost'><a href={buttonExternalLink} target="_blank">{buttonChild}</a></Button>}
            </div>
            {img && (
                <div className={`project--img ${imageClick ? 'clickable':''}`} ref={imgRef}>
                    {loading && <Spinner/>}
                    <img 
                        src={img}
                        style={{display: loading ? 'none' : 'block',}}
                        onLoad={handleImageLoad}
                        alt={`Obrázek projektu ${title}`}
                        onClick={imageClick}
                    />
                </div>
            )}
            {children && (
                <div className="project__text">
                    {children}
                </div>
            )} 
            {tags && (
                <div className="tags">
                    {tags.map((tag,index)=>(
                        <Tag key={index}>{tag}</Tag>
                    ))}
                </div>
            )}
            
        </div>
    );
};