import { AboutBlock } from "../blocks/aboutBlock/aboutBlock";
import { NewBodyBlock } from "../blocks/bodyBlock/bodyBlock";
import { ProfileBlock } from "../blocks/profile/profile";
import Layout from "./layout";

export function About(){

    return(
        <Layout>
            <NewBodyBlock variant='light' innerStyle={{marginTop:'0'}} style={{paddingTop:'16px'}}>
                <ProfileBlock/>
            </NewBodyBlock>
            <NewBodyBlock variant='dark'>
                <AboutBlock/>
            </NewBodyBlock>
        </Layout>
    );
};