import { CSSProperties } from "react";
import Footer from "../blocks/footer/footer";
import {Nav} from "../blocks/header/header"

type Props = {
    children:React.ReactNode,
    menuStyle?:CSSProperties,
    headerPos?:'def' | 'sec',
}

export default function Layout({children,menuStyle,headerPos='def'}:Props) {

    return(
        <>
        <Nav variant={headerPos}/>
        <main>{children}</main>
        <Footer></Footer>
        </>
    );
};